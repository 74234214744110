export const ADD_INGREDIENT = "ADD_INGREDIENT";
export const REMOVE_INGREDIENT = "REMOVE_INGREDIENT";
export const SET_INGREDIENTS = "SET_INGREDIENTS";
export const FETCH_INGREDIENT_FAILS = "FETCH_INGREDIENT_FAILS";
export const PERCHACE_BURGER_START = "PERCHACE_BURGER_START";

export const PURCHASE_BURGER_SUCESS = "PURCHASE_BURGER_SUCESS";
export const PURCHASE_BURGER_FAIL = "PURCHASE_BURGER_FAIL";
export const PURCHASE_INIT = "PURCHASE_INIT";

export const FETCH_ORDERS_START = "FETCH_ORDERS_START";
export const FETCH_ORDERS_SUCESS = "FETCH_ORDERS_SUCESS";
export const FETCH_ORDERS_FAIL = "FETCH_ORDERS_FAIL";

export const AUTH_SUCESS = "AUTH_SUCESS";
export const AUTH_START = "AUTH_START";
export const AUTH_FAIL = "AUTH_FAIL";

export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";
